<header>
    <div class="homepage">
        <img class="circle" src="/assets/images/Ellipse 24.png" alt="" />
        <!-- Navbar -->
        <div class="navbar lgNv">
            <a href="#" class="imageLogo"><img src="/assets/images/PICKUPJOBS TRANSPARAN 1.png" alt="" /></a>
            <div class="humburger" [ngClass]="{ active: isHumburgerOpen }" (click)="isToggle()">
                <span class="bar"></span>
                <span class="bar"></span>
                <span class="bar"></span>
            </div>
            <ul class="nav" [ngClass]="{ active: isNavbarOpen }">
                <li><a [routerLink]="['']">Home</a></li>
                <li><a href="./error.html">Services</a></li>
                <li><a [routerLink]="['/schedule-routes']">Schedule Route</a></li>
                <li><a href="#">Live Route</a></li>
                <li><a href="#">Help</a></li>
                <div class="btns">
                    <a [routerLink]="['/login']" class="nav-active">Login</a>
                    <a [routerLink]="['/registration']" [queryParams]="{ userType: 'transporter' }" class="nav-btn">Are you a transporter</a>
                </div>
            </ul>
        </div>
    </div>
</header>
