<footer>
  <div class="wrapper">
    <div class="left">
      <p>Follow Us On Pickup Jobs Ltd. © 2023.</p>
      <p>
        Pickup Jobs Ltd. is a company registered in England and Wales.
        Company Number: 123456. Registered Address: Floor 3, 207 Regent
        Street, London, W1B 3HH.
      </p>
    </div>
    <div class="right">
      <p>Follow Us On!</p>
      <div class="wrapper">
        <div class="box"><i class="fa-brands fa-linkedin-in"></i></div>
        <div class="box"><i class="fa-brands fa-facebook-f"></i></div>
        <div class="box"><i class="fa-brands fa-instagram"></i></div>
        <div class="box"><i class="fa-brands fa-twitter"></i></div>
        <div class="box"><i class="fa-brands fa-youtube"></i></div>
      </div>
    </div>
  </div>
  <div class="links">
    <p>About</p>
    <p>Blog</p>
    <p>Help</p>
    <p>Privacy</p>
    <p>Terms</p>
    <p>Sitemap</p>
  </div>
</footer>